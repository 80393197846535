import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, onMounted } from 'vue';
import useTablePagination from '@/hooks/pagination';
import { useStore } from 'vuex';
import Cookies from "js-cookie";
import { ExclamationCircleOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons-vue';
import { selectHolidayHistoryByPagination } from '@/api/officeApi';
import { selectMyHolidayRecord } from '@/api/personnel'; //表头

const columns = [{
  // title: '请假时间',
  dataIndex: 'time',
  width: '320px',
  slots: {
    customRender: 'time',
    title: 'customTitle'
  }
}, {
  title: '请假时长(天)',
  dataIndex: 'leaveDay',
  align: 'center'
}, {
  title: '请假类型',
  dataIndex: 'leaveType',
  align: 'center',
  slots: {
    customRender: 'leaveType'
  }
} // {
//   title: '处理方式',
//   dataIndex: 'leaveEffect',
//   slots: { customRender: 'leaveEffect' },
// },
];
export default defineComponent({
  components: {
    ExclamationCircleOutlined,
    CaretUpOutlined,
    CaretDownOutlined
  },

  setup() {
    //表格
    const tableData = ref([]);
    const loading = ref(false);
    const isShowUp = ref(0);
    let flag = ref(null); //获取表格数据

    const getTableData = async () => {
      loading.value = true;

      try {
        const res = await selectMyHolidayRecord();

        if (res.code === 200) {
          tableData.value = [];
          Object.assign(tableData.value, res.data ? [res.data] : []);
          isShowUp.value = res.data.isShowUp;
          loading.value = false;
        } else {
          loading.value = false;

          _message.error(res.message);
        }
      } catch {
        loading.value = false;
      }
    }; //查看历史请假记录


    const historicalVisible = ref(false);
    const historicalTableData = ref([]);
    const historicalUid = ref(''); //分页信息

    const historical = useTablePagination(); //点击分页

    const onPageChangehistorical = pagination => {
      historical.current.value = pagination.current;
      getHistoricalTableData();
    };

    const getHistoricalTableData = () => {
      return new Promise((resolve, reject) => {
        const param = {
          pageNumber: historical.current.value,
          limit: historical.pageSize.value ? historical.pageSize.value : 10,
          uid: historicalUid.value,
          flag: flag.value
        };
        selectHolidayHistoryByPagination(param).then(res => {
          try {
            const arr = [];
            historicalTableData.value = [];
            if (res.data.list) res.data.list.forEach(item => {
              let time = item.startTime.split(",");
              arr.push({ ...item,
                time
              });
            });
            Object.assign(historicalTableData.value, arr);
            historical.total.value = res.data.countSize;
            historical.current.value = res.data.nowPage;
            historical.pageSize.value = res.data.pageSize;
          } catch (error) {
            console.log(error);
          }

          resolve(true);
        }).catch(error => {
          console.log('查询历史请假记录', error);
        });
      });
    };

    const toView = async key => {
      historicalUid.value = key;
      getHistoricalTableData().then(res => {
        try {
          historicalVisible.value = true;
        } catch (error) {
          console.log(error);
        }
      });
    };

    const clickFlag = val => {
      flag.value = val;
      getHistoricalTableData();
    };

    onMounted(() => {
      getTableData();
    });
    return {
      columns,
      tableData,
      loading,
      toView,
      historicalVisible,
      historicalTableData,
      historical,
      onPageChangehistorical,
      historicalPaginationConfig: historical.paginationConfig,
      isShowUp,
      flag,
      clickFlag
    };
  }

});